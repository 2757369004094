.wrapper {
    margin-top: 22px;
    margin-bottom: 60px;
}
.title {
    font-size: 28px;
    margin-bottom: 11px;
}

.headerIcon {
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.nationIcon {
    @extend .headerIcon;
    background-image: url("./images/nation-icon.png");
}

.classIcon {
    @extend .headerIcon;
    background-image: url("./images/class-icon.png");
}

.efficiency {
    display: flex;
    padding-right: 10px;

    &:before {
        @extend .headerIcon;
        content: "";
        display: block;
        height: 18px;
        width: 25px;
        background-size: contain;
        background-position: center;
        background-image: url("./images/efficiency-icon.png");
    }
}

.shipName {
    display: flex;
    &:before {
        @extend .headerIcon;
        content: "";
        display: block;
        height: 18px;
        width: 65px;
        background-image: url("./images/ship-icon.png");
    }
}

.battlesCount {
    display: flex;
    &:before {
        @extend .headerIcon;
        content: "";
        display: block;
        height: 18px;
        width: 16px;
        background-image: url("./images/battles-icon.png");
        margin-right: 6px;
    }
}

.winsCount {
    display: flex;
    &:before {
        @extend .headerIcon;
        content: "";
        display: block;
        height: 18px;
        width: 18px;
        background-image: url("./images/wins-icon.png");
        margin-right: 6px;
    }
}

.xpCount {
    display: flex;
    &:before {
        @extend .headerIcon;
        content: "";
        display: block;
        height: 18px;
        width: 19px;
        background-image: url("./images/xp-icon.png");
        margin-right: 6px;
    }
}

.nationCellIcon {
    width: 27px;
    height: 27px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}

.shipCellIcon {
    min-height: 35px;
    min-width: 65px;
    max-height: 35px;
    max-width: 65px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform: translateY(-6px);
    @media (max-width: 769px) {
        min-height: 25px;
        min-width: 45px;
        max-height: 25px;
        max-width: 45px;
    }
}

.shipCellName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text {
    font-size: 16px;
    color: #fff;
}

.yellow {
    color: #fc6;
}

.boldCaps {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.03em;
}

.hideOnMobile {
    @media (max-width: 769px) {
        display: none;
    }
}

.hideLabelOnMobile {
    @media (max-width: 769px) {
        & > span {
            display: none;
        }
    }
}

.filters {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;

    div[class^="wru__Input__inner"] {
        padding: 0;
    }
}

.masteryComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 88px;

    & :global(.we-mastery__inline) {
        width: 25px;
        height: 25px;
    }
}

:global {
    .header-cell-nation,
    .header-cell-class,
    .header-cell-level,
    .cell-nation,
    .cell-class,
    .cell-level {
        min-width: 60px;
        justify-content: center;
        @media (max-width: 769px) {
            display: none;
        }
    }

    .cell-mastery {
        min-width: 150px;
        justify-content: center;
    }

    .header-cell-name,
    .cell-name {
        flex-grow: 1;
        max-width: 640px;
    } 

    .header-cell-battles_count,
    .header-cell-wins,
    .cell-battles_count,
    .cell-wins {
        min-width: 110px;
        @media (max-width: 769px) {
            min-width: 60px;
            & > div {
                & > span {
                    display: none;
                }
            }
        }
    }

    .header-cell-premium_exp,
    .header-cell-mastery {
        min-width: 150px;
    }

    .header-cell-battles_count,
    .cell-battles_count,
    .header-cell-wins,
    .cell-wins,
    .header-cell-premium_exp,
    .header-cell-mastery,
    .cell-premium_exp {
        padding-right: 20px;
        justify-content: center;
    }

    .header-cell-premium_exp,
    .cell-premium_exp {
        min-width: 150px;
        @media (max-width: 769px) {
            min-width: 80px;
            & > div {
                & > span {
                    display: none;
                }
            }
        }
    }
}
